.mouse {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border: 2px solid #fafafa;
	border-radius: 60px;
	&::before {
		content: '';
		width: 12px;
		height: 12px;
		position: absolute;
		top: 20%;
		left: 50%;
		transform: translateX(-50%);
		background-color: #fafafa;
		border-radius: 50%;
		opacity: 1;
		animation: wheel 2s infinite;
		-webkit-animation: wheel 2s infinite;
	}
}

@keyframes wheel {
	to {
		opacity: 0;
		top: 70%;
	}
}

@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 70%;
	}
}